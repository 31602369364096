import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Timeline from '../components/timeline/timeline';

const Biography = (props) => {
  const timelineInfo = props.data.allContentfulBiography.nodes[0].biographyElement;
  console.log(props);
  return (
    <Layout location={props.location}>
      <Helmet>
        <title>Biography - {props.data.site.siteMetadata.title}</title>
        <meta name="description" content={props.data.site.siteMetadata.description} />
      </Helmet>
      <h2 className="section-headline">Biography</h2>
      <Row>
        <Col>
          <Timeline data={timelineInfo} />
        </Col>
      </Row>
    </Layout>
  );
};

export const BiographyQuery = graphql`
  query BiographyQuery {
    allContentfulBiography {
      nodes {
        id
        biographyElement {
          icon
          id
          type
          title
          subtitle
          text {
            text
          }
          date
        }
      }
    }
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;

export default Biography;
