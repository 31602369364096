import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faBriefcase, faGraduationCap, faStar } from '@fortawesome/free-solid-svg-icons';
import './timeline.css';

const Timeline = ({ data }) => {
  library.add(faHeart, faBriefcase, faGraduationCap, faStar);

  return (
    <VerticalTimeline>
      {data.map((node) => {
        return (
          <VerticalTimelineElement
            key={node.id}
            className={`vertical-timeline-element--${node.type}`}
            contentArrowStyle={{ borderRight: '7px solid  #ddd' }}
            date={node.date}
            icon={<FontAwesomeIcon icon={node.icon} />}
          >
            <h3 className="vertical-timeline-element-title">{node.title}</h3>
            <h4 className="vertical-timeline-element-subtitle">{node.subtitle}</h4>
            <p>{node.text.text}</p>
          </VerticalTimelineElement>
        );
      })}
      ;
    </VerticalTimeline>
  );
};

export default Timeline;
